<template>
  <v-container v-if="state.service">
    <v-sheet v-if="getImage" class="logo-container m-0">
      <div :style="{backgroundImage: `url(${getImage})`}" class="logo-container__image"></div>
    </v-sheet>

    <v-sheet v-else class="icon-container secondary-background-button-color">
      <Icon icon="service-icon"/>
    </v-sheet>

    <v-row>
      <v-col>
        <v-sheet class="pa-3 ma-1 text-center sw-nav" color="white">
          <span class="p-head-title">{{ state.service.name }}</span>
        </v-sheet>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <p class="font-size-12-400 text-center">
          {{ getDuration }} mins
          <span class="secondary-button-color">|</span>
          {{ state.service?.price_display }}
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-sheet class="d-flex align-center justify-center ga-2 mt-2" color="white">
          <Icon icon="location-icon"/>
          <Icon icon="phone-icon"/>
          <Icon icon="video-icon"/>
        </v-sheet>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="ma-auto mt-2 d-flex align-center justify-center">
        <v-sheet v-if="getCreatorDetails" class="avatar-container">
          <div v-if="getCreatorDetails.image.file.file_path"
               :style="{ backgroundImage: `url(${getCreatorDetails.image.file.file_path})` }"
               class="avatar-container__image">
          </div>
          <div v-else class="avatar-container__initials primary-background-button-color">
            {{ getInitials(getCreatorDetails.first_name, getCreatorDetails.last_name).initials }}
          </div>
          <v-overlay
              activator="parent"
              location-strategy="connected"
              scroll-strategy="close"
          >
            <v-card class="pa-2">
              {{ getCreatorDetails.name }}
            </v-card>
          </v-overlay>
        </v-sheet>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-sheet class="px-3 ma-1 text-center overflow-y-auto" color="white" height="100">
          <span class="p-text-sm">{{ state.service?.description }}</span>
        </v-sheet>
      </v-col>
    </v-row>

    <v-sheet class="mt-1" width="100%">
      <v-row>
        <v-col class="text-center" cols="12">
          <v-sheet class="px-3 ma-1 text-center sw-nav" color="white">
            <ButtonBase
                :custom-class="['primary-button text-white mb-3']"
                text="Book Now"
                width="100%"
                @click="router.push({name: 'book-service', params: { id: state.service.id }})"
            />
            <ButtonBase
                :custom-class="['white-btn font-size-12-600']"
                text="Add to Cart"
                width="100%"
                @click="addService()"
            />
          </v-sheet>
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script lang="ts" setup>
import {computed, reactive} from 'vue';
import {useRouter, useRoute} from 'vue-router';
import {servicesService} from '@/api/services/service';
import {useSettingsStore} from '@/api/store/settings';
import {useCartStore} from '@/api/store/cart';
import ButtonBase from "@/components/custom/ButtonBase.vue";
import Icon from "@/components/Icons/Icon.vue";
import {getInitials} from "@/components/Helpers/Utils.ts";

const router = useRouter();
const route = useRoute();

const settingsStore = useSettingsStore();
const cartStore = useCartStore();

const state = reactive({
  service: null as any | null,
});

const loadService = async () => {
  settingsStore.loading = true;
  let serviceId = Number(route.params.id);
  state.service = await servicesService.getService(serviceId);
  settingsStore.loading = false;
};

loadService();

const SUPPLIER_INDEX = null; //null = clinic shop

const addService = () => {
  settingsStore.loading = true;
  cartStore.addItem(SUPPLIER_INDEX, state.service.id, 1, "App\\Models\\v3\\ClinicService").then((res) => {
    console.log(res);
    router.push({name: 'shopping-cart'});
  }).catch((error) => {
    settingsStore.error = true;
    settingsStore.error_message = "This item may be out of stock or no longer available from the supplier."
  }).finally(() => {
    settingsStore.loading = false;
  });
};

const getImage = computed(() => {
  if (!state.service.image && !state.service.image?.file) return null;

  return state.service.image?.file?.file_path;
})

const getDuration = computed(() => {
  return state.service?.booking_options?.duration ?? 0;
})

const getCreatorDetails = computed(() => {
  return state.service?.creator ?? null;
})
</script>

<style lang="scss" scoped>
.logo-container {
  width: 280.34px;
  height: 200.25px;
  margin: auto;

  &__image {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.icon-container {
  border-radius: 5px;
  width: 280.34px;
  height: 200.25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.avatar-container {
  border-radius: 100%;
  width: 41.45px;
  height: 41.45px;

  &__image {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__initials {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: #fff;
    font-size: 12px;
    border-radius: 50%;
  }
}
</style>