<template>
  <v-container v-if="state.package">
    <v-sheet v-if="getImage" class="logo-container m-0">
      <div :style="{backgroundImage: `url(${getImage})`}" class="logo-container__image"></div>
    </v-sheet>

    <v-sheet v-else class="icon-container secondary-background-button-color">
      <Icon icon="package-icon"/>
    </v-sheet>

    <v-row>
      <v-col>
        <v-sheet class="pa-3 ma-1 text-center sw-nav" color="white">
          <span class="p-head-title">{{ state.package.name }}</span>
        </v-sheet>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <p class="font-size-12-400 text-center">
          {{ getTotalServices }}
          <span class="secondary-button-color">|</span>
          {{ state.package?.price_display }}
        </p>
      </v-col>
    </v-row>

    <v-row v-if="state.package?.services">
      <v-col class="text-center">
        <v-sheet v-for="(service, index) in state.package?.services" :key="`package-services-${index}`" color="white">
          <p class="font-size-12-400 pa-1">({{ index + 1 }}) {{ service.name }}</p>
        </v-sheet>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-sheet class="d-flex align-center justify-center ga-2 mt-2" color="white">
          <Icon icon="location-icon"/>
          <Icon icon="phone-icon"/>
          <Icon icon="video-icon"/>
        </v-sheet>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-sheet class="px-3 ma-1 text-center overflow-y-auto" color="white" height="100">
          <span class="p-text-sm">{{ state.package?.description }}</span>
        </v-sheet>
      </v-col>
    </v-row>

    <v-sheet class="mt-1" width="100%">
      <v-row>
        <v-col class="text-center" cols="12">
          <v-sheet class="px-3 ma-1 text-center sw-nav" color="white">
            <ButtonBase
                :custom-class="['primary-button text-white mb-3']"
                disabled
                text="Book Now"
                width="100%"
                @click="router.push({name: 'book-service',
                 params: {
                  id: state.service.clinic_service_id ,
                },
                 query: {
                  packageServiceId: state.service.id 
                }
                })"
            />
            <ButtonBase
                :custom-class="['white-btn font-size-12-600']"
                text="Add to Cart"
                width="100%"
                @click="addPackage()"
            />
          </v-sheet>
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script lang="ts" setup>
import {computed, onMounted, reactive} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {packagesService} from '@/api/services/package';
import {useSettingsStore} from '@/api/store/settings';
import {useCartStore} from '@/api/store/cart';
import ButtonBase from "@/components/custom/ButtonBase.vue";
import Icon from "@/components/Icons/Icon.vue";

const router = useRouter();
const route = useRoute();

const settingsStore = useSettingsStore();
const cartStore = useCartStore();

const SUPPLIER_INDEX = null;

const state = reactive({
  package: null as any | null,
  service: null as any | null
});

const loadPackage = async () => {
  settingsStore.loading = true;
  const packageId = Number(route.params.id);

  await packagesService.show(packageId).then((response) => {
    state.package = response.package;
    // get the first service from the package in order to book it
    state.service = response.package?.services[0];
    router.currentRoute.value.meta.title = state.package.name;
  }).catch((err) => {
    console.log(err)
  })

  settingsStore.loading = false;
};

const addPackage = () => {
  settingsStore.loading = true;
  cartStore.addItem(SUPPLIER_INDEX, state.package.id, 1, "App\\Models\\v3\\ClinicPackage").then((res) => {
    router.push({name: 'shopping-cart'});
  }).catch((error) => {
    settingsStore.error = true;
    settingsStore.error_message = "This item may be out of stock or no longer available from the supplier."
  }).finally(() => {
    settingsStore.loading = false;
  });
};

const getImage = computed(() => {
  if (!state.package.image && !state.package.image?.file) return null;

  return state.package.image?.file?.file_path;
})

const getTotalServices = computed(() => {
  const totalServices = state.package?.services.length ?? 0;
  return totalServices > 1 ? `${totalServices} Services` : `${totalServices} Service`;
})

onMounted(loadPackage)
</script>

<style lang="scss" scoped>
.logo-container {
  width: 280.34px;
  height: 200.25px;
  margin: auto;

  &__image {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.icon-container {
  border-radius: 5px;
  width: 280.34px;
  height: 200.25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
</style>