<template>
  <section class="order-card-details">
    <v-sheet
        :class="{
        'clinic-logo-border secondary-background-button-color': getSupplier === 'clinic', 
        'logo-default-border': getSupplier?.name !== 'clinic'
      }"
        class="logo-container__content"
    >
      <div :style="{backgroundImage: `url(${getClinicAvatar})`}" class="logo-container__image"></div>
    </v-sheet>

    <v-card-text class="card-description pa-0">
      <section class="d-flex justify-space-between font-size-18-600">
        <div>
          {{ getItemsCount }} items
        </div>
        <div>
          {{ item?.currency }}{{ (cartData?.total_retail - cartData?.discount).toFixed(2) }}
        </div>
      </section>

      <section class="d-flex justify-space-between font-size-10-400">
        <div class="font-grey-dark">
          <p class="mt-1">Items SubTotal:</p>
          <p class="mt-1">Tax:</p>
          <p class="mt-1">Discount:</p>
          <p class="mt-1">Shipping:</p>
        </div>
        <div class="text-right">
          <p class="mt-1">{{ item?.currency }}{{ getCartPrice }}</p>
          <p class="mt-1">{{ item?.currency }}{{ cartData?.tax.toFixed(2) }}</p>
          <p class="mt-1">{{ getDiscount }}</p>
          <p class="mt-1">{{ item?.currency }}{{ cartData?.total_shipping.toFixed(2) }}</p>
        </div>
      </section>
    </v-card-text>
  </section>

  <section class="d-flex align-center ga-12 justify-space-between mt-9">
    <v-text-field
        v-model="couponCode"
        :disabled="isCouponApplied"
        class="input-round-20"
        density="compact"
        elevation="0"
        hide-details
        placeholder="Coupon code"
        rounded
        variant="solo"
        width="100%"
    />
    <div>
      <ButtonBase
          v-if="!isCouponApplied"
          :disabled="disableApplyButton"
          custom-class="text-white b-round-20 outline-btn white-btn"
          text="Apply"
          width="104px"
          @click="onApplyCoupon"
      />
      <ButtonBase
          v-else
          custom-class="text-white b-round-20 outline-btn remove-btn"
          text="Remove"
          width="104px"
          @click="onRemoveCoupon"
      />
    </div>
  </section>

  <p class="font-size-10-400 error-color pa-2">{{ errorMessage }}</p>
</template>

<script lang="ts" setup>
import {defineProps, inject, computed, shallowRef, onMounted, watch} from 'vue';
import ButtonBase from "@/components/custom/ButtonBase.vue";
import {cartService} from "@/api/services/cart";

const props = defineProps({
  item: {
    type: Object,
  },
});

const cartData = shallowRef<string>(null);
const isCouponApplied = shallowRef<boolean>(false);
const couponCode = shallowRef<string | null>(null);
const errorMessage = shallowRef<string>('');

const snackBarPlugin = inject('snackbar');

const getSupplier = computed(() => {
  return props.item?.supplier ?? 'clinic';
});

const getClinicAvatar = computed(() => {
  if (getSupplier.value == 'clinic') {
    return props.item?.clinic_avatar?.file?.file_path ?? '';
  }
  return '/img/tnd_hands_logo.png';
});

const getItemsCount = computed(() => {
  let quantity = 0;

  cartData.value?.items.forEach((item) => {
    quantity += item.quantity;
  });

  return quantity ?? 0;
});

const getDiscount = computed(() => {
  if (cartData.value?.discount_code) {
    return `-${props.item?.currency}${cartData.value.discount.toFixed(2)}`;
  }

  return `${props.item?.currency}0.00`;
});

const disableApplyButton = computed(() => {
  return !couponCode.value || isCouponApplied.value;
});

const getCartPrice = computed(() => {
  return (cartData.value?.total_retail - cartData.value?.tax - cartData.value?.discount).toFixed(2);
});

const onApplyCoupon = () => {
  cartService.applyDiscount(cartData.value.id, couponCode.value)
      .then((response) => {
        isCouponApplied.value = true;
        errorMessage.value = '';

        if (response.cart) {
          cartData.value = response.cart;
          snackBarPlugin.successSnackbar('Coupon applied successfully');
        }
      })
      .catch((error) => {
        errorMessage.value = 'Failed to apply coupon';
        snackBarPlugin.errorSnackbar('Failed to apply coupon');
        console.error(error);
      });
};

const onRemoveCoupon = () => {
  cartService.removeDiscount(cartData.value.id)
      .then((response) => {
        isCouponApplied.value = false;
        couponCode.value = null;
        errorMessage.value = '';

        if (response.cart) {
          cartData.value = response.cart;
          snackBarPlugin.successSnackbar('Coupon removed successfully');
        }
      })
      .catch((error) => {
        errorMessage.value = 'Failed to remove coupon';
        snackBarPlugin.errorSnackbar('Failed to remove coupon');
        console.error(error);
      });
};

watch(() => props.item, (newVal) => {
  cartData.value = newVal;
});

onMounted(() => {
  cartData.value = props.item;
  if (cartData.value.discount_code) {
    isCouponApplied.value = true;
    couponCode.value = cartData.value.discount_code.code;
  }
})
</script>

<style lang="scss" scoped>
.order-card-details {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 20px;

  .logo-container {
    position: absolute;
    left: 0;
    right: 0;
    top: -80px;
    z-index: 1;

    &__content {
      margin: auto;
      border-radius: 30%;
      padding: 10px;
      width: 100px;
      height: 100px;
    }

    &__image {
      border-radius: 50%;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .logo-default-border {
    border: 1px solid #304978;
    background: #3A5485;
  }
}

:deep(.v-field--variant-solo, .v-field--variant-solo-filled) {
  box-shadow: none;
  border: 1px solid #0000001A;
}

:deep(.v-field__field) {
  height: 38px;
}

:deep(.v-field__field) {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}

.error-color {
  color: red;
}

.remove-btn {
  color: red !important;
  border: 1px solid red;
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF),
  linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);

  span {
    color: red !important;
  }
}
</style>